<template>
	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
	<BaseLayout>
		<Toolbar @clickLeft="$router.go(-1)" @clickRight="$router.push('/cart')">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ product.name[currentLang] }}</p>
			<template v-slot:end>
				<div class="relative">
					<Icon class="h-5 w-5" icon="cart" />
					<span class="absolute -right-3 -top-3 inline-flex h-5 w-5 items-center justify-center rounded-full border-2 border-primary bg-white text-xs text-primary" v-if="cartProductQuantity > 0">{{ cartProductQuantity > 99 ? '99+' : cartProductQuantity }}</span>
				</div>
			</template>
		</Toolbar>
		<div class="relative w-full">
			<!-- <div :id="product.id" class="absolute w-full -top-52"></div> -->
			<img :id="product.id" class="aspect-square w-full" :src="product.imageUrls[0]" />
		</div>
		<div class="flex flex-col items-start space-y-3 p-5">
			<div class="text-2xl font-black text-primary">
				<p v-if="product.variants[0].isCustomPrice">{{ $t('categories.quoteSeparately') }}</p>
				<p v-else-if="product.variants[0].originalPrice[saleMethod] > product.variants[0].sellingPrice[saleMethod]" class="flex flex-row-reverse items-end gap-2 text-red-500">
					<del class="text-lg text-muted">HK$ {{ product.variants[0].originalPrice[saleMethod] }}</del>
					<span>HK$ {{ product.variants[0].sellingPrice[saleMethod] }}</span>
				</p>
				<p v-else>HK$ {{ product.variants[0].sellingPrice[saleMethod] }}</p>
			</div>
			<div class="inline-flex w-full justify-between text-primary">
				<p class="text-lg">{{ product.sku }}</p>
				<div v-if="product.brandName" class="inline-flex h-7 max-w-max items-center rounded-full bg-primary px-5 text-xs text-white">{{ product.brandName }}</div>
			</div>
			<div class="flex w-full items-end justify-between">
				<p class="text-xl font-bold">{{ product.name[currentLang] }}</p>
				<p class="text-xs leading-none">{{ $t('product.picture') }}</p>
			</div>

			<p class="break-all pb-5 text-lg disable-scrollbars">{{ product.description[currentLang] }}</p>
		</div>

		<Footer>
			<div v-if="customer">
				<div class="mb-5 grid h-12 grid-cols-6">
					<div class="col-span-5 flex justify-between rounded-lg bg-light-blue p-5 py-3 text-primary">
						<!-- 減少購物車數量 -->
						<button @click="minusQuantity" :disabled="isLoading">
							<Icon class="h-6 w-6" icon="minus" />
						</button>
						<!-- 購物車數量 -->
						<input type="number" min="1" max="9999" inputmode="numeric" pattern="[0-9]*" class="w-full bg-transparent text-center text-2xl" v-model="quantity" @change="changeQuantity()" />
						<!-- <p class="text-2xl">{{ currentProduct.orderedQuantity }}</p> -->
						<!-- 增加購物車數量 -->
						<button @click="addQuantity" :disabled="isLoading">
							<Icon class="h-6 w-6" icon="plus" />
						</button>
					</div>
					<div class="col-span-1 flex justify-self-end">
						<button class="rounded-lg bg-light-blue p-2" :class="inWishlist(currentProduct.id) >= 0 ? 'text-red-500' : 'text-white'" @click="toggleWishlist(currentProduct.id)">
							<Icon class="h-6 w-6" icon="heart" />
						</button>
					</div>
				</div>
				<Button class="inline-flex justify-between px-5" :variant="$route.query.index ? 'danger' : 'primary'" :disabled="isLoading" @click="toggleCart">
					<p>{{ $route.query.index ? $t('product.remove') : $t('product.add') }} {{ currentProduct.orderedQuantity + $t('product.cart') }}</p>
					<p>{{ product.variants[0].isCustomPrice ? $t('categories.quoteSeparately') : '$' + Math.round(product.variants[0].sellingPrice[saleMethod] * currentProduct.orderedQuantity * 10) / 10 }}</p>
				</Button>
			</div>
			<div v-else>
				<Button @click="$router.push('/launch')">{{ $t('product.login') }}</Button>
			</div>
		</Footer>
	</BaseLayout>
</template>

<script>
import { appType, saleType } from '../../package.json'
import { storeToRefs } from 'pinia'
import { ref, computed, nextTick, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useShopStore } from '@/store/shop'
import { useCatalogStore } from '@/store/catalog'
import { useCustomerStore } from '@/store/customer'
import { useCartStore } from '@/store/cart'
import { saleApi } from 'gox-sdk'
import { useI18n } from 'vue-i18n'

export default {
	setup() {
		const route = useRoute()
		const router = useRouter()
		const store = useStore()
		const { t } = useI18n({ useScope: 'global' })
		const { currentLang } = storeToRefs(useSharedStore())
		const { bindDoc } = useSharedStore()
		const { currentShop } = storeToRefs(useShopStore())
		const { product } = storeToRefs(useCatalogStore())
		const { currentCustomer } = storeToRefs(useCustomerStore())
		const { toggleWishlist, inWishlist } = useCustomerStore()
		const { currentProduct, currentCart, saleMethod } = storeToRefs(useCartStore())

		// const id = computed(() => route.params.id)
		const customer = currentCustomer.value
		// const product = currentProduct.value
		// const inWishlist = computed(() => store.getters.inWishlist(id.value))
		// const inCart = computed(() => store.getters.inCartProduct(id.value))
		const quantity = ref(currentProduct.value.orderedQuantity)
		// const cartQuantity = computed(() => store.getters.cartProductQuantity(id.value))

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		const cartProductQuantity = computed(() => {
			if (currentCart.value.id == null) return 0
			const cartProducts = currentCart.value.products.filter((product) => product.status === 'CLOSED')
			return cartProducts.reduce((sum, p) => sum + p.orderedQuantity, 0)
		})

		onMounted(() => {
			// if (!id.value || !product.value) router.push('/home')
			scrollToTop()
		})

		function scrollToTop() {
			const element = document.getElementById(currentProduct.value?.id)
			nextTick(() => {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' })
			})
		}

		// async function toggleWishlist() {
		// 	try {
		// 		store.commit('setLoadingState', { loadingState: true })
		// 		error.value = null
		// 		// await store.dispatch(inWishlist.value ? 'deleteWishlist' : 'createWishlist', { product: product.value })
		// 		store.commit('setLoadingState', { loadingState: false })
		// 	} catch (e) {
		// 		console.error(e)
		// 		error.value = e
		// 		store.commit('setLoadingState', { loadingState: false })
		// 	}
		// }
		async function minusQuantity() {
			try {
				const orderedQuantity = currentProduct.value.orderedQuantity - 1
				if (orderedQuantity < 1) return

				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				const saleProductRequest = {
					id: currentProduct.value.id,
					variantId: currentProduct.value.variantId,
					sellingPrice: currentProduct.value.sellingPrice,
					orderedQuantity: orderedQuantity,
				}

				if (route.query.index) await saleApi.updateSaleProduct(currentCart.value.id, route.query.index, saleProductRequest)
				currentProduct.value.orderedQuantity = orderedQuantity
				quantity.value = orderedQuantity

				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}
		async function changeQuantity() {
			try {
				const orderedQuantity = quantity.value
				if (orderedQuantity == 0 || orderedQuantity == '') return (currentProduct.value.orderedQuantity = 1)

				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				const saleProductRequest = {
					id: currentProduct.value.id,
					variantId: currentProduct.value.variantId,
					sellingPrice: currentProduct.value.sellingPrice,
					orderedQuantity: orderedQuantity,
				}

				if (route.query.index) await saleApi.updateSaleProduct(currentCart.value.id, route.query.index, saleProductRequest)
				currentProduct.value.orderedQuantity = orderedQuantity

				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				quantity.value = currentProduct.value.orderedQuantity
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}
		async function addQuantity() {
			try {
				const orderedQuantity = currentProduct.value.orderedQuantity + 1
				if (orderedQuantity > 99) return

				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				const saleProductRequest = {
					id: currentProduct.value.id,
					variantId: currentProduct.value.variantId,
					sellingPrice: currentProduct.value.sellingPrice,
					orderedQuantity: orderedQuantity,
				}

				if (route.query.index) await saleApi.updateSaleProduct(currentCart.value.id, route.query.index, saleProductRequest)
				currentProduct.value.orderedQuantity = orderedQuantity
				quantity.value = orderedQuantity

				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				if (error.value.message.includes('STO001')) {
					error.value.message = t('error.STO001')
				}
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function toggleCart() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				// filterCurrentCustomerDraftSales()
				error.value = null
				const saleProductRequest = {
					id: currentProduct.value.id,
					variantId: currentProduct.value.variantId,
					sellingPrice: currentProduct.value.sellingPrice,
					orderedQuantity: currentProduct.value.orderedQuantity,
					packedQuantity: '',
				}
				const saleId = ref(currentCart.value.id)
				if (!saleId.value) saleId.value = await createDraftCart()
				if (!route.query.index) await saleApi.createSaleProduct(saleId.value, saleProductRequest)
				if (route.query.index) await saleApi.cancelSaleProduct(currentCart.value.id, route.query.index)
				error.value = null
				store.commit('setLoadingState', { loadingState: false })
				router.go(-1)
				// await store.dispatch(inCart.value ? 'removeProductFromCart' : 'addProductToCart', { product: product.value, quantity: quantity.value })
			} catch (e) {
				console.error(e)
				error.value = e
				if (error.value.message.includes('STO001')) {
					error.value.message = t('error.STO001')
				}
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function createDraftCart() {
			const saleRequest = {
				shopId: currentShop.value.id,
				draftingAppType: appType,
				customerId: currentCustomer.value.id,
				type: saleType,
				method: currentCart.value.method,
				// pickupSetting: '',
				// deliverySetting: '',
			}
			const draftSaleResult = await saleApi.draftSale(saleRequest)
			bindDoc('draftCart', 'sales', draftSaleResult.id)
			return draftSaleResult.id
		}

		return {
			currentLang,
			customer,
			product,
			currentProduct,
			inWishlist,
			// inCart,
			quantity,
			// cartQuantity,
			error,
			isLoading,
			toggleWishlist,
			minusQuantity,
			changeQuantity,
			addQuantity,
			toggleCart,
			cartProductQuantity,
			currentCart,
			saleMethod,
		}
	},
}
</script>
